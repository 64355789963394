export const Home = '/';
export const OSS = '/oss';
export const Blog = '/blog';
export const CV = '/cv';
export const DownloadPdf = '/download-pdf';
export const DownloadWordDoc = '/download-word-doc';
export const PrivacyPolicy = '/privacy-policy';
export const TermsOfService = '/terms-of-service';
export const ContactMe = '/contact-me';
export const FunctionPlot = '/function-plot';
export const Sine2 = '/sine2';
export const Tan = '/tan';
export const Sinusoidal = '/sinusoidal';
export const Polar = '/polar';
